
















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private H: any = "";
  private get cState() {
    return this.$store.state.myState;
  }
  private get user() {
    return this.$store.state.user;
  }
  private goRouter(type: any, path: any) {
    this.$store.commit("updateMyState", type);
    this.$router.push(path);
  }
  mounted() {
    this.H = document.body.clientHeight - 50 - 14 - 14 - 14;
  }
}
